// const base_url = 'http://www.ekol24.com.tr/'
// http://www.ekol24.com.tr/epics/user/getLiveCasinos?authToken=s3cr3tV4lu3

// const base_url = 'http://ekol.ekoapi.com/'
// const base_url = 'https://wingo24.com'
const base_url = "https://api.ibaterm.com";
const authToken = "s3cr3tV4lu3";
function template(strings, ...keys) {
  return (...values) => {
    const dict = values[values.length - 1] || {};
    const result = [strings[0]];
    keys.forEach((key, i) => {
      const value = Number.isInteger
(key)
 ? values[key] : dict[key];
      result.push(value, strings[i + 1]);
    });
    return result.join("");
  };
}
const tz = Intl.DateTimeFormat().resolvedOptions().timeZone;
let object = {
  base_url,
  reqId: "1ab2cd3ef",
  urls: {
    // logn: `${base_url}epics/user/userLogin?authToken=${authToken}`,
    // live: `${base_url}epics/api/lives?authToken=${authToken}`,
    // lang: `${base_url}epics/user/getLanguages?authToken=${authToken}`,
    // data: `${base_url}epics/user/getUserData?authToken=${authToken}`,
    // csno: `${base_url}epics/user/getLiveCasinos?authToken=${authToken}`,
    // bngo: `${base_url}epics/casinos/get/tombalalive?authToken=${authToken}`,
    // opcs: `${base_url}epics/casinos/open?authToken=${authToken}`,
    // sprt: `${base_url}epics/api/sports?authToken=${authToken}`,
    // mtch: `${base_url}epics/api/matches?authToken=${authToken}`,
    // ctgr: `${base_url}epics/api/categories?authToken=${authToken}`,
    // tour: `${base_url}epics/api/tournaments?authToken=${authToken}`,
    // ucps: `${base_url}epics/user/getCoupons?authToken=${authToken}`,
    // ucpd: `${base_url}epics/user/getCouponDetail?authToken=${authToken}`,
    // utrs: `${base_url}epics/user/getTransactions?authToken=${authToken}`,
    // socket: 'http://filliale.ekoapi.com/'
    // socket: 'http://159.69.59.155:3003/',
    // socket: 'wss://data.apiekol.com/wsold',
    // socket: "wss://lv.apiekol.com/wsgo",
    socket: "wss://btrd.apiepics.com/wsold",
    mdetail: "https://tracker.datamixpot.com/?matchId=",
    // sdetail: 'https://href.li/?http://stats.betradar.com/s4/?clientid=850&matchid=',
    sdetail: "http://www.ekol24.com/epics/welcome/getLeagueFixture/",
    bingo:
      "http://embed.tombalaklas.com?token=8873c865797d618a4bb6e750a75e3ee3&amp;sendData=1&amp;origin=http://localhost:3001",
  },
  uris: {
    logn: `epics/user/userLogin?authToken=${authToken}`,
    // live: `epics/api/lives3?authToken=${authToken}`,
    live: `sportbooks/live/lives3`,
    odds: `sportbooks/pre/odds?authToken=${authToken}`,
    livo: `sportbooks/live/liveodds?authToken=${authToken}`,
    lang: `epics/user/getLanguages?authToken=${authToken}`,
    cntr: `epics/user/getCountries?authToken=${authToken}`,
    curr: `epics/user/getCurrencies?authToken=${authToken}`,
    ttic: `epics/user/ticketCheckTerminal?authToken=${authToken}`,
    ttbc: `epics/user/bingoCheckTerminal?authToken=${authToken}`,
    copc: `epics/user/couponCanceled?authToken=${authToken}`,
    usru: `epics/user/updateProfile?authToken=${authToken}`,
    usrd: `epics/user/getUserDetail?authToken=${authToken}`,
    usrb: `epics/user/getUserBills?authToken=${authToken}`,
    rsta: `epics/user/cutOffUserBill?authToken=${authToken}`,
    usrp: `epics/user/changePassword?authToken=${authToken}`,
    data: `epics/user/getUserDataTerminal?authToken=${authToken}`,
    lngcnhg: `epics/user/updateLangTerminal?authToken=${authToken}`,
    csno: `epics/user/getLiveCasinos?authToken=${authToken}`,
    csnw: `epics/user/getAllCasinosNew?authToken=${authToken}`,
    tmvd: `epics/user/getBingoVideo?authToken=${authToken}`,
    bngo: `epics/casinos/get/tombalalive?authToken=${authToken}`,
    opcs: `epics/casinos/open?authToken=${authToken}`,
    sprt: `sportbooks/pre/sports?authToken=${authToken}`,
    mtch: `sportbooks/pre/matches?authToken=${authToken}`,
    mtcr: `epics/api/results?authToken=${authToken}`,
    ctgr: `sportbooks/pre/categories?authToken=${authToken}`,
    tour: `sportbooks/pre/tournaments?authToken=${authToken}`,
    ucps: `epics/user/getCoupons?authToken=${authToken}`,
    ucpd: `epics/user/getCouponDetail?authToken=${authToken}`,
    seco: `epics/user/sellCoupon?authToken=${authToken}`,
    prsc: `epics/user/printerstatus?authToken=${authToken}`,
    prst: `epics/user/printerstatuswithdraw?authToken=${authToken}`,
    prcn: `epics/user/canceltransaction?authToken=${authToken}`,
    prcc: `epics/user/printingCouponCancel?authToken=${authToken}`,
    addm: `epics/terminal/deposit?authToken=${authToken}`,
    wtpr: `epics/user/withdrawPrinterTerminal?authToken=${authToken}`,
    utrs: `epics/user/getTransactions?authToken=${authToken}`,
    crcp: `epics/couponapi/createcoupon?authToken=${authToken}`,
    sckupdt: `epics/couponapi/updatesocket?authToken=${authToken}`,
    wtcd: `epics/user/winprint?tz=${tz}&type=2&authToken=${authToken}`,
  },
  tprt: {
    bingoprint: template`${base_url}/epics/user/bingoprinter/${0}?userId=${1}&timezone=${tz}&${2}`,
  },
  prints: {
    // winprint: base_url + '/epics/user/winprint/{{lang}}/{{couponId}}?siteType={{siteType}}&timezone=' + tz,
    winprintbingo:
      base_url +
      "/epics/user/winprintbingo/{{lang}}/{{couponId}}?siteType={{siteType}}&userId={{userId}}&timezone=" +
      tz,
    // cancelprint: base_url + '/epics/user/cancelprint/{{lang}}/{{couponId}}?timezone=' + tz,
    winprint: `${base_url}/epics/user/winprintterminal/{{lang}}/{{couponId}}?timezone=${tz}`,
    cancelprint: `${base_url}/epics/user/cancelprint/{{lang}}/{{couponId}}/true?timezone=${tz}`,
    couponprint: `${base_url}/epics/user/printer/{{lang}}/{{couponId}}?timezone=${tz}`,
    duplicateprint:
      base_url +
      "/epics/user/printer/{{lang}}/{{couponId}}/duplicate?timezone=" +
      tz,
    withdrawprint:
      base_url +
      "/epics/user/withdrawPrinterTerminal/{{lang}}/{{transactionId}}?userId={{userId}}&timezone=" +
      tz,
    resetprint: `${base_url}/epics/user/resetprint/{{lang}}/{{billId}}?timezone=${tz}`,
    bingoprint:
      base_url +
      "/epics/user/bingoprinter/{{lang}}?userId={{userId}}&timezone=" +
      tz +
      "&{{paramaters}}",
    directory: "C:/tickets/",
    tmpDirectory: "C:/ticketstmp/",
    filePathCashDevice: "C:/cashdevice/Executer.exe",
    filePathUsername: "C:/cashdevice/usrx.txt",
    filePathMoney: "C:/cashdevice/cashdevice.json",
    filePathTicketControl: "C:/cashdevice/ticketcontrols.json",
    // filePathDb: 'C:/cashdevice/'
  },
  prgPath: "C:/terminal",
  files: {
    firstRun: "firstrun.cfg",
    logs: "terminal.log",
    settings: "settings.json",
  },
  compare_field: "oddstype",
  maxMatchItem: 9,
  couponLimits: {
    maxMultiOdds: 27,
    maxMultiCps: 10,
    maxOddsCoupon: 20,
    maxSystemCps: 10,
  },

  defaultPrefs: {
    activeWindows: ["main"],
    windows: ["---", "main", "LiveMatches", "TombalaVideo"],
    disabledWindows: [""],
    hasExternalDisplay: false,
    printer: 1,
    onlineCheck: 1,
    userType: "T",
  },
  languages: [
    {
      lang: "tr",
      label: "Türkçe",
    },
    {
      lang: "en",
      label: "English",
    },
    {
      lang: "de",
      label: "Deutsch",
    },
    {
      lang: "fr",
      label: "Français",
    },
    {
      lang: "aa",
      label: "عربى",
    },
    {
      lang: "az",
      label: "Azərbaycan",
    },
    {
      lang: "cw",
      label: "Curaçao",
    },
  ],
  defaultColumns: [
    {
      sid: "3",
      markets: [1],
    },
    {
      sid: "1",
      markets: [1, 7, 8, 18],
    },
  ],
  idle_timer_minute: 5,
};

export default object;
import React from "react";
import { injectIntl, FormattedMessage } from "react-intl";
import { connect } from "react-redux";
import { Redux } from "../../components";
// import moment from "moment";
// import _ from "lodash";
import classNames from "classnames";

class LiveMatchRow extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      item: props.item,
      schema: props.schema,
      z: props.z,
      l: props.l,
      oe: props.oe,
      _mid: props._mid,
      halfTime: props.halfTime,
      favorite: props.item?.is_favorite ?? false,
    };
  }

  shouldComponentUpdate(nextProps, nextState) {
    if (nextProps?.schema != this.state.schema) {
      return true;
    }

    if (nextProps?._mid == this.state.item?.id) {
      // console.log(this.state.item.id, "<-------------------------------------------- UPDATED")
      // console.log(this.props.item)
      return true;
    }

    if (nextState?._mid == this.state.item?.id) {
      return true;
    }

    return false;
  }

  _removeAnimate(mid, oid) {
    oid.an = "";
    if (this.timeout) clearTimeout(this.timeout);
    this.timeout = setTimeout(() => {
      this.setState({ _mid: mid });
    }, 800);
  }

  _setFavorite(m, st) {
    m.is_favorite = !st;
    this.setState({ _mid: m.id });
    this.props.onAddFavorite(st ? -1 : 1);
  }

  _setStatus(item) {
    if (item.ms.includes("pause")) {
      return (
        <span
          style={{
            backgroundColor: "#7d7d28",
            height: "40px",
            width: "100%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          HT
        </span>
      );
    }
    if (item.sid == 1 && item.l) {
      return (
        <span>
          {item.ti}'{item.tie > 0 ? `+${item.tie}` : null}
        </span>
      );
    } else if (item.sid != 1 && item.l) {
      return <span>{item.ms}</span>;
    } else {
      return (
        <span
          style={{ fontSize: "15px" }}
          dangerouslySetInnerHTML={{ __html: item.d }}
        />
      );
    }
  }

  componentWillUnmount() {
    this.props.item.an = "";
    if (this.timeout !== undefined) clearTimeout(this.timeout);
  }

  render() {
    const { item, schema, z, l, oe, intl, coupon } = this.props;
    return (
      <div className="w-full">
        <div
          className={classNames({
            "match-row-cont": !item.ht,
            "match-row-cont-fp": item.ht,
          })}
        >
          {schema.map((s, sindex) => {
            // console.log('match schema', s)
            if (s.y == "o" && !s.ics) return null;
            return (
              <div
                key={sindex}
                data-box={z}
                data-col={l}
                className={classNames({
                  [`m-${s.y}`]: Boolean(s.y != "t" || (!item.ht && s.y == "t")),
                  [`m-t-2`]: Boolean(s.y == "t" && item.ht),
                  [`i-c-${oe}`]: true,
                  [`${item.cls}`]: true,
                })}
              >
                {s.y == "d" && this._setStatus(item)}

                {s.y == "t" && (
                  <>
                    <div
                      style={{
                        backgroundColor:
                          item.cls !== "bg-half-time" ? "#097F05" : undefined,
                      }}
                      className="live-match-teams-score "
                    >
                      <div className="live-match-teams">
                        <div className="live-match-teams-home">
                          <span>{item.h}</span>
                          {item?.stt?.c?.r?.h > 0 && (
                            <span className="red-card-home">
                              {item.stt.c.r.h}
                            </span>
                          )}
                        </div>

                        <div
                          onClick={() => {
                            this._setFavorite(item, item?.is_favorite ?? false);
                          }}
                          className="live-match-score"
                        >
                          {item?.scr && item.ms !== "not_started" ? (
                            <span>
                              {item.scr.h} : {item.scr.a}
                            </span>
                          ) : (
                            <span>-</span>
                          )}
                        </div>

                        <div className="live-match-teams-away">
                          <span>{item.a}</span>
                          {item?.stt?.c?.r?.a > 0 && (
                            <span className="red-card-away">
                              {item.stt.c.r.a}
                            </span>
                          )}
                        </div>
                      </div>
                      <div className="live-match-teams-flag">
                        <div className="flex flex-row items-center justify-center categoryNameMatch">
                          <div
                            onClick={() => {
                              this._setFavorite(
                                item,
                                item?.is_favorite ?? false
                              );
                            }}
                            className="live-match-favorite"
                          ></div>
                          <span>
                            {" "}
                            {
                              <img
                                src={`https://s.betimg.com/ekol/uploads/flags/${item.cid}.png`}
                              />
                            }
                          </span>
                          <span>{item.tn}</span>
                        </div>
                        {/* <span>{item.cn} </span> */}
                      </div>
                    </div>
                    {item.ht && (
                      <div
                        style={{
                          backgroundColor:
                            item.cls !== "bg-half-time" ? "#097F05" : undefined,
                        }}
                        className="live-match-fh-name"
                      >
                        <span
                          style={{ fontSize: "16px" }}
                          className="live-row-priod-name"
                        >
                          <FormattedMessage
                            id="lang_first_period"
                            defaultMessage="lang_first_period"
                          />
                        </span>
                      </div>
                    )}
                  </>
                )}

                {s.y == "o" && s.ics && (
                  <>
                    <div
                      style={{
                        backgroundColor:
                          item.cls == "bg-half-time" ? "#7d7d28" : undefined,
                      }}
                      className={`odd-group odd-group-${s.o.length}`}
                    >
                      {s.o.map((_o, oindex) => {
                        let g = undefined;
                        let j = undefined;

                        let _g = item?.o?.filter(
                          (x) =>
                            x.id == _o.t &&
                            !x.iss &&
                            x.isv &&
                            x.o.find((y) => !y.iss && y.isv)
                        );

                        // if (item.itie && o.t == 7 && _g?.length == 0) {
                        //     _g = item?.o?.filter(x => x.id == 1)
                        //     let _s = schema.filter(x => x.i == 1)
                        //     if (_s?.length > 0) {
                        //         _s = _s[0]
                        //         o = _s?.o[oindex] ?? _o
                        //     }
                        // }

                        if (_g?.length > 0) {
                          g = _g[0];

                          if (item.sid == 1) {
                            if (item.ith && _o.t == 18) {
                              _g = _g.filter((x) => x.spv == 2.5);
                              if (_g.length > 0) {
                                g = _g[0];
                              }
                            } else if (_o.t == 18) {
                              _g = _g.filter((x) => x.spv % 1 > 0);
                              if (_g.length > 0) {
                                g = _g[0];
                              }
                            }
                          }

                          const k = g?.o?.filter((x) => x.id == _o.i);

                          if (k?.length > 0) {
                            j = k[0];
                          }
                        }

                        const st = j?.st ?? "locked";

                        let is_added = false;
                        let cp = coupon.filter((x) => x.id == item.id);
                        if (cp?.length > 0) {
                          cp = cp[0];
                          is_added = Boolean(
                            cp.odd.filter((x) => x.uq == j?.uq).length > 0
                          );
                        }

                        return (
                          <div
                            key={`${j?.sid ?? oindex + 100_000}-${oindex}`}
                            className={classNames({
                              "live-odd-button-cont": true,
                            })}
                          >
                            {_o.o == ".s" && (
                              <div className="odd-special">{g?.spv ?? "-"}</div>
                            )}

                            {_o.o != ".s" && st == "" && (
                              <div
                                onClick={() => {
                                  Redux.addOrRemoveOdd(item, j, intl);
                                }}
                                className={classNames("active-odd", {
                                  "in-coupon": is_added,
                                })}
                              >
                                {j.o}
                              </div>
                            )}

                            {_o.o != ".s" && st == "locked" && (
                              <div className="locked-odd" />
                            )}

                            {_o.o != ".s" && st == "semi-locked" && (
                              <div className="semi-locked-odd">{j.o}</div>
                            )}

                            {_o.o != ".s" && st == "" && j?.an.length > 0 && (
                              <>
                                <span
                                  className={`fas market-animate-${j.an}`}
                                />
                                {this._removeAnimate(item.id, j)}
                              </>
                            )}
                          </div>
                        );
                      })}
                    </div>

                    {item.ht && (
                      <>
                        <div className="sep" />
                        {s.hf && s.fh?.length > 0 ? (
                          <div className={`odd-group odd-group-${s.o.length}`}>
                            {s.hf &&
                              s.fh?.length > 0 &&
                              item.ht &&
                              s.fh.map((_o, oindex) => {
                                let g = undefined;
                                let j = undefined;

                                let _g = item?.o?.filter(
                                  (x) =>
                                    x.id == _o.t &&
                                    !x.iss &&
                                    x.isv &&
                                    x.o.find((y) => !y.iss && y.isv)
                                );
                                // if (item.it && o.t == 999999 && _g.length == 0) {
                                //     _g = item?.o?.filter(x => x.id == 5518)
                                //     let _s = schema.filter(x => x.i == 5518)
                                //     if (_s?.length > 0) {
                                //         _s = _s[0]
                                //         o = _s?.o[oindex] ?? _o
                                //     }
                                // }

                                if (_g?.length > 0) {
                                  g = _g[0];

                                  if (item.sid == 1) {
                                    if (item.ith && _o.t == 5520) {
                                      _g = _g.filter((x) => x.spv == 0.5);
                                      if (_g.length > 0) {
                                        g = _g[0];
                                      }
                                    } else if (_o.t == 5520) {
                                      _g = _g.filter((x) => x.spv % 1 > 0);
                                      if (_g.length > 0) {
                                        g = _g[0];
                                      }
                                    }
                                  }

                                  const k = g?.o?.filter((x) => x.id == _o.i);

                                  if (k?.length > 0) {
                                    j = k[0];
                                  }
                                }

                                const st = j?.st ?? "locked";

                                let is_added = false;
                                let cp = coupon.filter((x) => x.id == item.id);

                                if (cp?.length > 0) {
                                  cp = cp[0];
                                  is_added = Boolean(
                                    cp.odd.filter((x) => x.uq == j?.uq).length >
                                      0
                                  );
                                }

                                return (
                                  <div
                                    key={`${
                                      j?.Id ?? oindex + 100_000
                                    }-${oindex}`}
                                    className={classNames({
                                      "live-odd-button-cont": true,
                                    })}
                                  >
                                    {_o.o == ".s" && (
                                      // <></>
                                      <div className="odd-special">
                                        {g?.spv ?? "-"}
                                      </div>
                                    )}

                                    {_o.o != ".s" && st == "" && (
                                      <div
                                        onClick={() => {
                                          Redux.addOrRemoveOdd(item, j, intl);
                                        }}
                                        className={classNames("active-odd", {
                                          "in-coupon": is_added,
                                        })}
                                      >
                                        {j.o}
                                      </div>
                                    )}

                                    {_o.o != ".s" && st == "locked" && (
                                      <div className="locked-odd" />
                                    )}

                                    {_o.o != ".s" && st == "semi-locked" && (
                                      <div className="semi-locked-odd">
                                        {j.o}
                                      </div>
                                    )}

                                    {_o.o != ".s" &&
                                      st == "" &&
                                      j?.an?.length > 0 && (
                                        <>
                                          <span
                                            className={`fas market-animate-${j.an}`}
                                          />
                                          {this._removeAnimate(item.id, j)}
                                        </>
                                      )}
                                  </div>
                                );
                              })}
                          </div>
                        ) : (
                          <div className={`odd-group odd-group-${s.o.length}`}>
                            {s.o.map((_o, oindex) => {
                              return (
                                <div
                                  key={`${oindex + 300_000}-${oindex}`}
                                  className={classNames({
                                    "live-odd-button-cont": true,
                                  })}
                                >
                                  <div key={oindex} className="locked-odd" />
                                </div>
                              );
                            })}
                          </div>
                        )}
                      </>
                    )}

                    {!s.hf && item.ht && (
                      <>
                        <div className="sep" />
                        <div className={`odd-group odd-group-${s.o.length}`}>
                          {s.o.map((o, oindex) => {
                            return (
                              <div
                                key={oindex + 200_000}
                                className={classNames({
                                  "live-odd-button-cont": true,
                                })}
                              >
                                <div className="locked-odd" />
                              </div>
                            );
                          })}
                        </div>
                      </>
                    )}
                  </>
                )}

                {s.y == "s" && (
                  <div
                    className={classNames({
                      "c-button-cont": true,
                      "opacity-30": Boolean(!item.istt || item.bs == "stopped"),
                    })}
                  >
                    <button
                      disabled={Boolean(!item.istt || item.bs == "stopped")}
                      onClick={() => {
                        this.props.onShowStatistics(
                          Redux.getStatisticsUrl(item.id)
                        );
                      }}
                    >
                      <i className="fas fa-chart-bar" />
                    </button>
                  </div>
                )}
                {/* THE PLUS BUTTON */}
                {s.y == "a" && (
                  <button
                    disabled={Boolean(item.oc < 1 || item.bs == "stopped")}
                    style={{
                      backgroundColor:
                        item.cls == "bg-half-time" ? "#7d7d28" : undefined,
                    }}
                    className={classNames({
                      "opacity-30": Boolean(
                        item.oc < 1 || item.bs == "stopped"
                      ),
                    })}
                    onClick={() => {
                      this.props.onDetail(item);
                    }}
                  >
                    {item.oc > 0 && item.bs != "stopped" && (
                      <span>+{item.oc}</span>
                    )}
                  </button>
                )}
              </div>
            );
          })}
        </div>
      </div>
    );
  }
}

const msp = (state) => {
  return {
    _mid: state._mid,
    coupon: state.coupon,
  };
};

export default connect(msp)(injectIntl(LiveMatchRow));
